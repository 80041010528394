<template>
  <div
    class="w-full h-full min-h-screen overflow-y-auto"
    style="background-color: #67dfa1"
  >
    <div v-if="error" class="text-center pt-24 text-3xl">{{ error }}</div>
    <div v-else class="text-center pt-24 text-3xl">Page Not Found</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: "",
    };
  },
  mounted() {
    this.error = this.$route.params.error;
  },
};
</script>

<style></style>
