import Vue from 'vue'
import VueRouter from 'vue-router'
import PaymentPage from '../views/PaymentPage.vue'
import P404 from '../views/404.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/pay/*',
    name: 'PaymentPage',
    component: PaymentPage,
  },

  {
    path: '*',
    name: 'ErrorPage',
    component: P404,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
