<template>
  <div class="w-full min-h-screen flex">
    <div class="md:w-1/2 w-full" style="background-color: #202f36">
      <div class="md:flex hidden justify-between">
        <div class="lg:mt-3 mt-1 ml-9 flex items-center">
          <!-- <div class="lg:mt-3 mt-1 ml-3" style="width: 172.91px; height: 54px"> -->
          <!-- <img src="../assets/mezopay-logo.svg" alt="mezogateway logo" /> -->
          <img
              src="../assets/mezogateway-logo.png"
              alt="mezogateway logo"
              class="lg:h-8 md:h-6 lg:w-8 md:w-6"
          />
          <p class="font-bold text-white lg:text-xl xl:text-3xl ml-2.5">mezogateway</p>
        </div>
        <div>
          <button
              style="background-color: #67dfa1"
              class="text-center font-medium lg:text-3xl text-2xl py-3 xl:px-16 lg:px-9 px-6"
          >
            Join for free
          </button>
        </div>
      </div>
      <div
          v-if="cancelAlert"
          class="bg-white rounded-b-3xl flex justify-between items-center pt-12 pb-2 px-8 top-0 fixed z-20 w-full lg:w-1/2"
      >
        <div style="font-size: 14px; color: #f95a2c" class="text-center">
          <p class="font-bold md:pl-10">{{ errMessage }}</p>
        </div>
        <div @click="cancelAlert = false">
          <img
              src="../assets/cancel-alert.svg"
              alt="cancel alert"
              class="cursor-pointer md:pr-10"
          />
        </div>
      </div>
      <div
          class="md:border border-gray-600 rounded-2xl lg:mt-24 mt-6 mb-12 xl:mx-24 lg:mx-18 mx-8"
      >
        <div v-if="!initialLoader" class="border-b border-gray-600 py-5 md:px-7 px-0">
          <div class="flex justify-between items-center">
            <div>
              <p class="capitalize font-bold text-white lg:text-3xl text-xl">
                {{ fetchTransaction.vendor.business_name }}
              </p>
              <p class="font-normal" style="font-size: 10.54px; color: #d2d5d7">
                mezovest.mezogateway
              </p>
            </div>
            <!--  -->
            <div
                class="lg:w-14 w-12 lg:h-14 h-12 relative rounded-full"
                style="background-color: #33454c"
            >
              <div
                  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <p class="font-bold text-white text-4xl">
                  {{ fetchTransaction.vendor.business_name.slice(0, 1) }}
                </p>
              </div>
            </div>
            <!-- <div class="lg:w-14 w-12 lg:h-14 h-12">
              <img
                src="../assets/coura-store.svg"
                alt="store avatar"
                class="inset-0 object-cover w-full h-full"
              />

            </div>-->
          </div>
        </div>

        <div v-if="transactionStatus == false && initialLoader == false">

          <div class="md:border-b border-gray-600 md:px-7 px-0 md:mb-0 mb-10 py-2.5">
            <div class="flex items-center justify-between">
              <div>
                <label
                    class="inline-flex items-center cursor-pointer"
                    for="Pay with bank transfer"
                ><input
                    v-model="payWith"
                    v-if="payWith != 2"
                    value="2"
                    type="radio"
                    class="w-4 h-4 mr-4"
                    style="color: #67dfa1"
                /><span
                    style="font-size: 16px"
                    :style="payWith == 2 ? 'color:#67dfa1' : 'color:#d2d5d7'"
                    class="font-semibold"
                >Pay with Bank Transfer</span
                ></label
                >
              </div>
              <div v-if="payWith == 2">
                <div class="text-white pr-5" style="font-size: 20px">
                  ₦
                  <span class="font-bold">
                    {{ (fetchTransaction.amount + fees).toLocaleString() }}
                  </span>
                </div>
              </div>
            </div>
            <div
                v-if="payWith == 2"
                class="mt-3 py-3 mb-6 bg-white rounded-2xl border border-green-400"
            >
              <div
                  class="flex justify-between items-center capitalize px-4 border-b border-gray-200 pb-2"
              >
                <div>
                  <p class="font-semibold" style="color: #798286; font-size: 13px">
                    Account Number
                  </p>
                </div>
                <div>
                  <p class="font-bold" style="color: #33454c; font-size: 15px">
                    {{ bankDetails.account_number }}
                  </p>
                </div>
              </div>
              <div
                  class="flex justify-between items-center capitalize px-4 py-2 border-b border-gray-200"
              >
                <div>
                  <p class="font-semibold" style="color: #798286; font-size: 13px">
                    Account Name
                  </p>
                </div>
                <div>
                  <p class="font-bold" style="color: #33454c; font-size: 15px">
                    {{ bankDetails.account_name }}
                  </p>
                </div>
              </div>
              <div class="flex justify-between items-center capitalize px-4 pt-2">
                <div>
                  <p class="font-semibold" style="color: #798286; font-size: 13px">
                    Bank Name
                  </p>
                </div>
                <div>
                  <p class="font-bold" style="color: #33454c; font-size: 15px">
                    Wema Bank Plc
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="md:border-b border-gray-600 md:px-7 px-0 md:mb-0 md-5 py-2.5">
            <div class="flex justify-between items-center">
              <label
                  class="inline-flex items-center cursor-pointer"
                  for="Pay with Mezogateway"
              ><input
                  v-model="payWith"
                  v-if="payWith != 1"
                  value="1"
                  type="radio"
                  class="w-4 h-4 mr-4"
                  style="color: #67dfa1"
              /><span
                  style="font-size: 16px"
                  :style="payWith == 1 ? 'color:#67dfa1' : 'color:#d2d5d7'"
                  class="font-semibold"
              >Pay with Mezogateway</span
              ></label
              >
              <div v-if="payWith == 1 || payWith == 0">
                <div class="text-white pr-5" style="font-size: 20px">
                  ₦
                  <span class="font-bold">{{
                      fetchTransaction.amount.toLocaleString()
                    }}</span>
                </div>
              </div>
            </div>

            <div
                v-if="payWith == 1 && pinInput == false"
                class="mt-3 py-3 mb-6 bg-white rounded-2xl border border-green-400"
            >
              <div class="flex justify-between items-center capitalize px-4 pb-8">
                <div>
                  <p class="font-semibold" style="color: #798286; font-size: 12.667px">
                    Your email, username or number?
                  </p>
                </div>
                <div>
                  <img
                      src="../assets/user.svg"
                      alt="user icon"
                      style="width: 16.29px; height: 17.97px"
                  />
                </div>
              </div>

              <div class="w-full capitalize px-4 pt-2">
                <div>
                  <input
                      v-model="userDetail"
                      class="w-full font-semibold uppercase border-none focus:outline-none"
                      style="color: #202f36; font-size: 21.6px"
                      placeholder="lukmonu@gmail.com"
                  />
                </div>
              </div>
            </div>

            <div
                v-if="pinInput == true && payWith == 1"
                class="mt-3 py-3 mb-6 bg-white rounded-2xl border border-green-400"
            >
              <div class="flex justify-between items-center capitalize px-4 pb-8">
                <div>
                  <p
                      v-if="otpField"
                      class="font-bold"
                      style="color: #798286; font-size: 15.28px"
                  >
                    Enter the OTP code sent to you
                  </p>
                  <p v-else class="font-bold" style="color: #798286; font-size: 15.28px">
                    Enter your transaction PIN
                  </p>
                </div>
                <div class="animate-pulse">
                  <img
                      src="../assets/pin-field.svg"
                      alt="user icon"
                      style="width: 32.81px; height: 24.09px"
                  />
                </div>
              </div>

              <div class="pt-2">
                <div class="flex justify-center w-full">
                  <v-otp-input
                      v-if="!pinLoader"
                      ref="otpInput"
                      input-type="password"
                      input-classes="otp-input"
                      separator=""
                      :num-inputs="4"
                      :shouldAutoFocus="true"
                      @on-complete="handleOnComplete"
                      @on-change="handleOnChange"
                  />
                  <div v-if="pinLoader" class="flex justify-center">
                    <img
                        class="animate-spin"
                        src="../assets/loading-payment.svg"
                        alt="loading payment"
                        style="width: 64px; height: 64px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="mt-3 py-1.5 px-4">
            <div class="flex justify-center relative">
              <button
                  v-if="payWith == 1"
                  @click="emailErr"
                  class="py-4 lg:px-20 px-10 sm:px-18 font-extrabold rounded-3xl focus:outline-none flex items-center"
                  style="font-size: 16.81px; background: #67dfa1"
              >
                <p>Continue Payment</p>
                <img
                    src="../assets/payment-icon.svg"
                    class="pl-3.5"
                    alt="payment button icon"
                />
              </button>
              <button
                  v-if="payWith != 1"
                  @click="continuePayment"
                  class="py-4 lg:px-20 px-10 sm:px-18 font-extrabold rounded-3xl focus:outline-none flex items-center"
                  style="font-size: 16.81px"
                  :style="loading ? 'background: #CCF4E0;' : 'background: #67dfa1'"
              >
                <p v-if="payWith == 0" :class="loading ? 'opacity-0' : 'opacity-100'">
                  Continue Payment
                </p>
                <p v-else :class="loading ? 'opacity-0' : 'opacity-100'">
                  Confirm Payment
                </p>
                <img
                    :class="loading ? 'opacity-0' : 'opacity-100'"
                    src="../assets/payment-icon.svg"
                    class="pl-3.5"
                    alt="payment button icon"
                />

                <img
                    v-if="loading"
                    src="../assets/loading.svg"
                    class="animate-spin absolute right-1/2"
                    alt="payment button icon"
                />
              </button>
            </div>
            <div class="text-center pt-4 pb-12" style="font-size: 12.56px">
              <p class="text-white">
                Don't have an account?<span style="color: #67dfa1"> Register</span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="transactionStatus == true" class="pt-20 pb-32">
          <div class="flex justify-center">
            <img
                v-if="!paySuccess"
                class="animate-spin"
                src="../assets/loading-payment.svg"
                alt="loading payment"
                style="width: 64px; height: 64px"
            />
            <img
                v-if="paySuccess"
                class=""
                src="../assets/checked-payment.svg"
                alt="loading payment"
                style="width: 64px; height: 64px"
            />
          </div>
          <div>
            <p
                :class="!paySuccess ? 'opacity-0' : 'opacity-100'"
                class="text-center py-3.5"
                style="font-size: 14px; color: #67dfa1"
            >
              Paid
            </p>
            <p class="text-white text-center" style="font-size: 16px">
              <span v-if="paySuccess">You’ve paid &nbsp;</span>
              <span v-else>You are about to pay &nbsp;</span>
              <b
              >{{ fetchTransaction.vendor.business_name }}<br />
                ₦{{ fetchTransaction.amount.toLocaleString() }}
              </b>
              for this transaction.
            </p>
          </div>
        </div>
        <!-- Initial Loader -->
        <div v-if="initialLoader" class="pt-28 pb-28">
          <div class="flex justify-center">
            <img
                class="animate-spin"
                src="../assets/loading-payment.svg"
                alt="loading"
                style="width: 128px; height: 128px"
            />
          </div>
        </div>
      </div>

      <div @click="cancelButton" class="flex justify-center pb-14">
        <img src="../assets/cancel.svg" class="w-8 h-8 cursor-pointer" alt="" />
      </div>
    </div>
    <div class="w-1/2 md:block hidden relative" style="background-color: #67dfa1">
      <div class="h-full w-full" style="">
        <img
            v-if="transactionStatus == true"
            src="../assets/confirm-avatar.svg"
            alt="mezopay avatar"
            class="object-fill absolute left-0 bottom-0"
            style="height: 550px"
        />
        <img
            v-else
            src="../assets/avatar.svg"
            alt="mezogateway avatar"
            class="object-fill absolute left-0 bottom-0"
            style="height: 550px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      payWith: 2,
      cancelAlert: false,
      loading: false,
      paySuccess: false,
      otpField: false,
      pinLoader: false,
      initialLoader: false,
      pin: "",
      otp: "",
      pinChange: "",
      otpChange: "",
      userDetail: "",
      errMessage: "",
      reference: "",
      pinInput: false,
      transactionStatus: false,
      bankDetails: {},
      fees: 0,
      fetchTransaction: {
        amount: 0,
        vendor: {
          business_name: "...",
        },
      },
      // bankCharge: 20,
      payWithBankAmount: "",
    };
  },
  computed: {},
  methods: {
    referenceNo() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return (this.reference = text);
    },
    cancelButton() {
      this.payWith = 0;
      this.pinInput = false;
      this.transactionStatus = false;

      if(!this.payWith && !this.pinInput && !this.transactionStatus) {
        window.location = this.fetchTransaction.callback_url
      }
    },
    emailErr() {
      if (this.payWith == 1) {
        if (this.userDetail == "") {
          this.errMessage = "This field is required";
          this.cancelAlert = true;
          setTimeout(() => {
            this.cancelAlert = false;
          }, 15000);
        } else this.pinInput = true;
        //

        if (this.otpField == false) {
          if (this.otpChange.length == 0) {
            return;
          }
        }
        if (this.otpField == true) {
          if (this.otpChange.length != 3) {
            this.errMessage = "Please enter the OTP code completely";
            this.cancelAlert = true;
            setTimeout(() => {
              this.cancelAlert = false;
            }, 15000);
          }
        } else {
          if (this.pinChange.length != 3) {
            this.errMessage = "Please enter your transaction PIN";
            this.cancelAlert = true;
            setTimeout(() => {
              this.cancelAlert = false;
            }, 15000);
          }
        }
      }
    },
    async continuePayment() {
      if (this.payWith == 0) {
        this.payWith = 1;
        return;
      } else if (this.payWith == 1) {
        this.sendOTP();
      } else this.confirmBankPayment();

      // let url = "https://mezopay-gateway.herokuapp.com/public/api/pay/wallet/initialize";
      // let token = "Bearer MPUB129951579098990284107";
      // if (this.payWith == 1) {
      //   this.paymentData.payment_option = "mezogateway";
      // } else this.paymentData.payment_option = "bank_transfer";

      // if (this.pinInput == true) {
      //   this.loading = true;
      //   this.referenceNo();

      //   let formData = new FormData();
      //   formData.append("email", this.userDetail);
      //   formData.append("phone", this.userDetail);
      //   formData.append("payment_option", this.paymentData.payment_option);
      //   formData.append("reference", this.reference);
      //   formData.append("currency", "NGN");
      //   formData.append("callback_url", this.fetchTransaction.callback_url);
      //   formData.append("amount", this.fetchTransaction.amount);

      //   await axios
      //     .post(url, formData, { headers: { Authorization: token } })
      //     .then((res) => {
      //       //console.log(res);
      //       this.paymentData.payment_code = res.data.data.payment_code;
      //       this.paymentData.payment_option = res.data.data.payment_option;

      //       this.sendOTP();

      //     })
      //     .catch((err) => {
      //       //console.log(err.response.data.data.message);
      //       this.errMessage = err.response.data.data.message;
      //       this.cancelAlert = true;
      //       this.loading = false;
      //       this.pinLoader = false;
      //       this.pinInput = true;
      //       setTimeout(() => {
      //         this.cancelAlert = false;
      //       }, 15000);
      //   });
      //  } else this.verifyTrans();
    },
    async sendOTP() {
      this.loading = true;
      let url = "https://gateway.mezogateway.com/api/pay/send-otp";
      let token = "Bearer MPUB129951579098990284107";

      let formData = new FormData();
      formData.append("validate_with", this.userDetail);
      formData.append("payment_code", this.payCode);
      formData.append("pin", this.pin);

      await axios
          .post(url, formData, { headers: { Authorization: token } })
          .then(() => {
            //console.log(res);
            this.loading = false;
            this.pinLoader = false;
            this.pinInput = true;
            this.otpField = true;
          })
          .catch((err) => {
            // console.log(err.response.data.data.message);
            this.errMessage = err.response.data.data.message;
            this.cancelAlert = true;
            this.loading = false;
            this.pinLoader = false;
            if (this.errMessage == "Invalid transaction pin") {
              this.pinInput = true;
            } else if (this.errMessage == "Invalid Account Credentials") {
              this.pinInput = false;
            } else this.pinInput = false;

            setTimeout(() => {
              this.cancelAlert = false;
            }, 15000);
          });
    },
    async authorizeTrans() {
      let url = "https://gateway.mezogateway.com/api/pay/authorize";
      let token = "Bearer MPUB129951579098990284107";

      this.loading = true;
      this.transactionStatus = true;

      let formData = new FormData();
      formData.append("validate_with", this.userDetail);
      formData.append("amount", this.fetchTransaction.amount);
      formData.append("payment_link", this.fetchTransaction.payment_code);
      formData.append("pin", this.pin);
      formData.append("code", this.otp);

      await axios
          .post(url, formData, { headers: { Authorization: token } })
          .then((res) => {


            //console.log(res);
            this.loading = false;
            this.paySuccess = true;

            if(res.data.result === 'success'){

              let url = new URL(this.fetchTransaction.callback_url);
              let cbUrl = this.fetchTransaction.callback_url;
              if(url.search){
                cbUrl+= '&tx_ref='+this.fetchTransaction.payment_reference
              }else {
                cbUrl+='?tx_ref='+this.fetchTransaction.payment_reference
              }




              setTimeout(() => {
                window.location = cbUrl;
              }, 1000);
            }
          })
          .catch((err) => {
            // console.log(err.response.data.data.message);
            this.errMessage = err.response.data.data.message;
            this.cancelAlert = true;
            (this.transactionStatus = false), (this.loading = false);
            (this.pinInput = true), (this.otpField = true);
            setTimeout(() => {
              this.cancelAlert = false;
            }, 15000);
          });
    },
    async verifyTrans() {
      this.transactionStatus = true;
      let url = "https://gateway.mezogateway.com/api/pay/verify";
      let token = "Bearer MPUB129951579098990284107";

      let formData = new FormData();
      formData.append("reference", this.fetchTransaction.payment_reference);

      await axios
          .post(url, formData, { headers: { Authorization: token } })
          .then((res) => {
            console.log(res);
            if (res.data.data.status == "successful") {
              this.paySuccess = true;
              setTimeout(() => {
                window.location = this.fetchTransaction.callback_url;
              }, 2000);
            } else {
              this.transactionStatus = false;
              this.errMessage = "Bank account not credited yet";
              this.cancelAlert = true;
              setTimeout(() => {
                this.cancelAlert = false;
              }, 15000);
            }
          })
          .catch((err) => {
            //console.log(err.response.data.message);
            this.transactionStatus = false;
            this.errMessage = err.response.data.message;
            this.cancelAlert = true;
            setTimeout(() => {
              this.cancelAlert = false;
            }, 15000);
          });
    },
    async fetchTransactionLoad() {
      let url = "https://gateway.mezogateway.com/api/pay/fetch-transaction";
      let token = "Bearer MPUB129951579098990284107";

      let formData = new FormData();
      formData.append("payment_code", this.payCode);

      await axios
          .post(url, formData, { headers: { Authorization: token } })
          .then(async (res) => {
            // console.log(res);
            this.fetchTransaction = res.data.data;
            this.fees =  await this.getFees(this.fetchTransaction.amount);
            if (this.fetchTransaction.status == "successful") {
              this.$router.push({
                name: "ErrorPage",
                params: { error: "This payment has already been made." },
              });
            }
            if (this.fetchTransaction.status == "expired") {
              this.$router.push({
                name: "ErrorPage",
                params: { error: "This payment link has expired." },
              });
            }
            this.initialLoader = false;
          })
          .catch((err) => {
            console.log(err.response.data.data.message);
            let errorMsg = err.response.data.data.message;
            this.$router.push({
              name: "ErrorPage",
              params: { error: errorMsg },
            });
          });
    },
    async paywithBankTransfer() {
      let url =
        "https://gateway.mezogateway.com/api/pay/banktransfer/initialize";
      let token = "Bearer MPUB129951579098990284107";

      let formData = new FormData();
      formData.append("payment_code", this.payCode);

      await axios
          .post(url, formData, { headers: { Authorization: token } })
          .then((res) => {
            // console.log(res);
            this.bankDetails = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });

      this.autoConfirmBankPayment()
    },
    handleOnComplete(value) {
      if (this.otpField == true) {
        this.otp = value;
        this.authorizeTrans();
      } else {
        this.pin = value;
        //console.log(this.otp);
        this.$refs.otpInput.clearInput();
        this.cancelAlert = false;
        this.pinLoader = true;
        this.continuePayment();
      }
    },
    handleOnChange(value) {
      if (this.otpField == true) {
        this.otpChange = value;
      } else {
        this.pinChange = value;
      }
    },

    async getFees(amount){
      let url = "https://gateway.mezogateway.com/api/pay/banktransfer/fees";
      let token = "Bearer MPUB129951579098990284107";
      try {
        const { data } = await axios
            .post(url, {amount}, { headers: { Authorization: token } });
        return Promise.resolve(parseFloat(data.fees))
      }catch (e){
        return Promise.reject(e)
      }
    },
    async confirmBankPayment(){
      this.transactionStatus = true;
      let url = "https://gateway.mezogateway.com/api/pay/fetch-transaction";
      let token = "Bearer MPUB129951579098990284107";

      let formData = new FormData();
      formData.append("payment_code", this.payCode);

      await axios
          .post(url, formData, { headers: { Authorization: token } })
          .then((res) => {

            let cbUrl = this.fetchTransaction.callback_url;

            let url = new URL(this.fetchTransaction.callback_url);

            if(url.search){
              cbUrl+= '&tx_ref='+this.fetchTransaction.payment_reference
            }else {
              cbUrl+='?tx_ref='+this.fetchTransaction.payment_reference
            }


            if (res.data.data.status == "successful") {
              this.paySuccess = true;
              setTimeout(() => {
                window.location = cbUrl;
              }, 2000);
            } else {
              this.transactionStatus = false;
              this.errMessage = "Bank account not credited yet";
              this.cancelAlert = true;
              setTimeout(() => {
                this.cancelAlert = false;
              }, 15000);
            }
          })
          .catch((err) => {
            //console.log(err.response.data.message);
            this.transactionStatus = false;
            this.errMessage = err.response.data.message;
            this.cancelAlert = true;
            setTimeout(() => {
              this.cancelAlert = false;
            }, 15000);
          });
    },
    autoConfirmBankPayment(){
     setInterval(() => {
        if(!this.transactionStatus) {
          this.confirmBankPayment()
        }
      }, 10000)
    }
  },


  async mounted() {
    this.initialLoader = true;
    let payCode = this.$router.currentRoute.fullPath;
    this.payCode = payCode.slice(5);
    //console.log(this.payCode);
    //
    this.fetchTransactionLoad();
    this.paywithBankTransfer();
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.otp-input {
  width: 60px !important;
  height: 60px !important;
  padding: 5px !important;
  margin: 0 10px !important;
  font-size: 38px !important;
  border-radius: 12px !important;
  border: 2px solid rgba(55, 65, 81, 1) !important;
  text-align: center !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .otp-input {
    width: 36px !important;
    height: 40px !important;
    font-size: 28px !important;
    padding: 2px !important;
  }
}
</style>
